@import '../../assets/scss/variables.scss';
.rightDrawer {
    right: 0;
    z-index: 6;
    top: 0;
    width: 92px;
    width: 500px;
    display: flex;
    position: fixed;
    flex-direction: column;
    height: 100dvh;
    transform: translateX(105%);
    transition: 0.3s ease-in-out;
    background-color: $color-white;
    border-left: 1px solid $color-gray300;
    @include max-screen($mobile) {
        width: 100%;
        max-width: 360px;
    }
    &.active {
        transform: translateX(0);
    }
    &__header {
        padding: 12px 32px;
        border-bottom: 1px solid $color-gray300;
        @include max-screen($mobile) {
            padding: 10px 15px;
        }
        h2 {
            font-size: 24px;
            font-weight: 600;
            margin-right: auto;
            width: calc(100% - 85px);
            @include max-screen($mobile) {
                font-size: 20px;
                width: calc(100% - 58px);
            }
        }
        img {
            cursor: pointer;
            &.isClose {
                width: 32px;
                @include max-screen($mobile) {
                    width: 20px;
                }
            }
            &.isLink {
                width: 19px;
                margin-right: 20px;
                @include max-screen($mobile) {
                    width: 15px;
                    margin-right: 10px;
                }
            }
        }
    }
    &__content {
        flex: auto;
        padding: 32px;
        overflow-y: auto;
        @include max-screen($mobile) {
            padding: 15px;
        }
    }
    &.noPadding {
        .rightDrawer__content {
            padding: 0;
            overflow: hidden;
            display: flex;
        }
    }
}