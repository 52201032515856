@import '../../../../assets/scss/variables.scss';
.customNodeView {
    position: relative;
    &__action {
        align-self: start;
        cursor: default;
        border: 0;
        background-color: transparent;
        @include min-screen($mobile) {
            width: 48px;
            opacity: 0;
            position: absolute;
            right: calc(100% + 5px);
            transition: 0.3s ease-in-out;
        }
        &.active {
            opacity: 1;
        }
    }
    &__content {
        @include max-screen($mobile) {
            display: flex;
        }
        >div[data-node-view-content] {
            @include max-screen($mobile) {
                width: calc(100% - 45px);
            }
        }
        &:hover {
            .customNodeView__action {
                opacity: 1;
            }
        }
    }
}