.skeleton {
    span {
        display: block;
    }
    br {
        display: none;
    }
    &.isFill {
        >span {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }
}