@import "../../assets/scss/variables.scss";
.header {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 7;
    position: fixed;
    padding: 14px 30px;
    background-color: $color-white;
    @include max-screen($desktop) {
        padding: 14px 30px;
    }
    @include max-screen($tablet) {
        padding: 14px 20px;
    }
    @include max-screen($mobile) {
        padding: 14px 15px;
    }
    &.borderBottom {
        border-bottom: 1px solid $color-gray300;
    }
    // &__logo {
    //     width: 186px;
    //     margin-right: auto;
    // }
    &__title {
        font-size: 24px;
        font-weight: 600;
        margin-right: auto;
        color: $color-primary;
        @include max-screen($mobile) {
            font-size: 20px;
        }
    }
    &__profile {
        cursor: pointer;
        img,
        picture {
            width: 32px;
            height: 32px;
            object-fit: cover;
            border-radius: 50%;
        }
        picture {
            display: flex;
            font-weight: 600;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            border: 1.5px solid $color-black;
        }
        aside {
            line-height: 1.3;
            padding-left: 6px;
            width: calc(100% - 32px);
            @include max-screen($mobile) {
                display: none;
            }
            strong {
                display: block;
                font-weight: 600;
            }
            span {
                display: block;
                font-size: 12px;
                color: $color-gray500;
            }
        }
    }
    &__notification {
        margin: 0 20px 0 auto;
        img {
            width: 26px;
            cursor: pointer;
        }
    }
    &__holder {
        width: 100%;
        height: 63px;
        @include max-screen($mobile) {
            height: 60px;
        }
    }
    &__profile {
        position: relative;
        ul {
            right: -2px;
            display: none;
            min-width: 165px;
            position: absolute;
            border-radius: $radius;
            top: calc(100% + 20px);
            transition: 0.3s ease-in-out;
            background-color: $color-white;
            box-shadow: 0 0 10px rgba($color-black, 0.1);
            @include max-screen($mobile) {
                left: 50%;
                min-width: 140px;
                top: calc(100% + 10px);
                transform: translateX(-50%);
            }
            &::before {
                right: 50%;
                content: "";
                bottom: 100%;
                position: absolute;
                transform: translateX(50%);
                border: 10px solid transparent;
                border-bottom-color: $color-white;
                @include max-screen($mobile) {
                    border-width: 7px;
                }
            }
            li {
                display: flex;
                cursor: pointer;
                font-weight: 500;
                padding: 13px 24px;
                align-items: center;
                white-space: nowrap;
                color: $color-gray600;
                transition: 0.3s ease-in-out;
                @include max-screen($mobile) {
                    padding: 8px 10px;
                }
                &:first-child {
                    border-top-left-radius: $radius;
                    border-top-right-radius: $radius;
                }
                &:last-child {
                    border-bottom-left-radius: $radius;
                    border-bottom-right-radius: $radius;
                }
                img {
                    width: 22px;
                    height: auto;
                    border-radius: 0;
                    margin-right: 10px;
                }
                &:hover {
                    background-color: rgba($color-black, 0.04);
                }
            }
            &.active {
                display: block;
            }
        }
    }
    &__notification {
        position: relative;
        margin: 0 20px 0 auto;
        img {
            width: 26px;
            cursor: pointer;
        }
        cite {
            top: -7px;
            right: -4px;
            width: 18px;
            height: 18px;
            cursor: pointer;
            font-size: 10px;
            position: absolute;
            border-radius: 50%;
            font-style: normal;
            background-color: $color-warning;
        }
    }
    &__profileHolder {
        cursor: pointer;
        >img {
            width: 32px;
            height: 32px;
            object-fit: cover;
            border-radius: 50%;
        }
        aside {
            line-height: 1.3;
            padding-left: 6px;
            width: calc(100% - 32px);
            @include max-screen($mobile) {
                display: none;
            }
            strong {
                display: block;
                font-weight: 600;
                max-width: 150px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            span {
                display: block;
                font-size: 12px;
                color: $color-gray500;
            }
        }
        strong {
            display: block;
            font-size: 14px;
            font-weight: 500;
            color: $color-black;
        }
        span {
            font-size: 8px;
            display: block;
            color: lighten($color-black, 60);
        }
    }
    &__hamburger {
        width: 25px;
        height: 15px;
        display: none;
        cursor: pointer;
        margin-left: 20px;
        position: relative;
        @include max-screen($tablet) {
            display: block;
        }
        span {
            top: 50%;
            right: 0;
            width: 100%;
            height: 2px;
            display: block;
            text-align: left;
            position: absolute;
            text-indent: -9999px;
            background: $color-primary;
            transform: translateY(-50%);
            transition: 0.3s ease-in-out background-color;
            &:before,
            &:after {
                right: 0;
                width: 100%;
                height: 2px;
                position: absolute;
                background: $color-primary;
                transition: 0.3s ease-in-out;
                content: "";
            }
            &:before {
                top: -8px;
            }
            &:after {
                bottom: -8px;
            }
        }
        &.active {
            span {
                background: transparent;

                &:before {
                    top: 0;
                    transform: rotate(-135deg);
                }

                &:after {
                    bottom: 0;
                    transform: rotate(135deg);
                }
            }
        }
    }
    &__notificationHeader {
        padding: 8px 15px;
        color: $color-white;
        border-radius: 10px 10px 0 0;
        background-color: $color-primary;
        a {
            color: $color-white;
        }
    }
    &__scroll {
        overflow-y: auto;
        max-height: 300px;
        padding: 5px 0 0;
    }
    &__notificationList {
        right: -20px;
        min-width: 300px;
        position: absolute;
        border-radius: 10px;
        top: calc(100% + 5px);
        background-color: $color-white;
        box-shadow: 0 1px 5px rgba($color-black, 0.1);
        @include max-screen($small) {
            min-width: 250px;
        }
        &::before {
            right: 33px;
            content: "";
            bottom: 100%;
            position: absolute;
            transform: translateX(50%);
            border: 7px solid transparent;
            border-bottom-color: $color-primary;
        }
        li {
            display: flex;
            padding: 8px 15px;
            cursor: pointer;
            align-items: center;
            background-color: rgba($color-black, 0.02);
            border-bottom: 1px solid rgba($color-black, 0.03);
            p {
                opacity: 0.7;
                display: block;
                font-size: 12px;
                font-weight: 700;
                padding-right: 10px;
                width: calc(100% - 20px);
                &.isRead {
                    font-weight: 400;
                }
            }
            img {
                width: 20px;
                cursor: pointer;
            }

            &:hover {
                p {
                    opacity: 1;
                }
            }
        }
    }
    &__notificationLoading {
        text-align: center;
        padding: 10px 15px;
    }
    &__notificationClear {
        font-size: 14px;
        padding: 8px 0 10px;
        a {
            color: $color-black;
        }
    }
    &.noBorder {
        border-bottom: 0;
    }
    &.isFull {
        width: 100%;
        .header__logo {
            display: block;
        }
        .header__hamburger {
            display: none;
        }
    }
    &.isGray {
        background-color: $color-gray-alt;
    }
}