@import '../../assets/scss/variables.scss';
.tagInput {
    h4 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 24px;
    }
    &__field {
        width: 100%;
        display: flex;
        outline: none;
        flex-wrap: wrap;
        min-height: 56px;
        padding: 8px 12px;
        border-radius: 12px;
        align-items: center;
        border: 1.5px solid $color-gray300;

        &::placeholder {
            color: $color-gray400;
        }
        input {
            border: 0;
            flex: auto;
            outline: none;
            padding: 5px 4px;
            font-size: 16px;
            appearance: none;
            font-weight: 500;
            min-width: 100px;
            color: $color-gray600;
            font-family: $font-family-base;
            background-color: transparent;
        }
    }
    &__button {
        margin: 2px;
        line-height: 1;
        min-height: 28px;
        font-size: 12px;
        border-radius: 20px;
        align-items: center;
        color: $color-white;
        display: inline-flex;
        padding: 2px 5px 2px 12px;
        background-color: $color-primary;
        cite {
            font-style: normal;
            word-wrap: break-word;
            max-width: calc(520px - 25px);
            @include max-screen($mobile) {
                max-width: calc(100vw - 144px);
            }
        }
        span {
            width: 18px;
            height: 18px;
            display: flex;
            cursor: pointer;
            border-radius: 50%;
            margin-left: 5px;
            align-items: center;
            color: $color-primary;
            justify-content: center;
            background-color: $color-white;
        }
    }
    &__error {
        font-size: 12px;
        margin-top: 3px;
        display: block;
        padding: 0 3px;
        color: $color-error;
    }
    &__list {
        margin-top: 28px;
    }
    &__item {
        margin: 20px 0;
    }
    &__email {
        font-size: 14px;
        font-weight: 600;
        color: $color-black;
        word-wrap: break-word;
        width: calc(100% - 120px);
    }
}