@import '../../assets/scss/variables.scss';
.comments {
    flex: auto;
    display: flex;
    flex-direction: column;
    &__scroll {
        flex: auto;
        overflow-y: auto;
        padding: 12px 0 32px;
        @include max-screen($mobile) {
            padding: 12px 0 15px;
        }
    }
    &__tab {
        margin-bottom: 24px;
    }
    &__sort {
        margin-bottom: 12px;
        a {
            font-size: 14px;
            font-weight: 500;
            align-items: center;
            display: inline-flex;
            &::after {
                content: '';
                height: 0;
                margin: 4px 0 0 4px;
                display: inline-block;
                border: 4px solid transparent;
                border-top-color: $color-black;
            }
        }
    }
    &__content {
        padding: 0 32px;
        @include max-screen($mobile) {
            padding: 0 15px;
        }
    }
    &__item {
        padding: 16px 0;
        font-size: 12px;
        max-width: 435px;
        color: $color-gray600;
        @include max-screen($mobile) {
            max-width: 325px;
        }
        >img {
            width: 32px;
            height: 32px;
            object-fit: cover;
            border-radius: 50%;
        }
        header {
            margin-bottom: 5px;
            p {
                width: 100%;
            }
        }
        p {
            word-wrap: break-word;
            white-space: pre-line;
        }
        .comments__input {
            max-width: 405px;
            @include max-screen($mobile) {
                max-width: 299px;
            }
        }
        div:has(>ul[role="listbox"]) {
            width: calc(100% + 17px) !important;
        }
        &:last-child {
            border-bottom: 0;
            margin-bottom: 10px;
        }
    }
    &__itemContent {
        padding: 10px;
        margin-left: 10px;
        width: calc(100% - 42px);
        border-radius: $radius;
        background-color: rgba($color-black, 0.05);
    }
    &__profile {
        width: calc(100% - 70px);
        strong {
            font-weight: 600;
            font-size: 14px;
        }

        p {
            font-size: 10px;
            color: $color-gray500;
            span {
                font-weight: 600;
            }
        }
    }
    &__action {
        display: none;
        align-self: start;
        margin-left: auto;
        img {
            width: 22px;
            cursor: pointer;
            &+img {
                margin-left: 10px;
            }
        }
    }
    &__input {
        max-width: 436px;
        position: relative;
        border-radius: 12px;
        margin: 20px 32px 10px;
        padding: 5px 55px 5px 16px;
        border: 1px solid $color-gray300;
        @include max-screen($mobile) {
            margin: 20px 15px 10px;
            max-width: 330px;
        }
        form {
            >div>div>div,
            >div>div,
            >div {
                max-height: 100px;
            }
            >div {
                min-height: 40px;
                >div:has([role="listbox"]) {
                    width: calc(100% + 70px);
                    max-height: 250px;
                    z-index: 2 !important;
                    top: calc(100% - 3px) !important;
                    left: -17px !important;
                    overflow-y: auto;
                    padding: 8px 0 !important;
                    border-radius: 10px;
                    margin: 10px 0 0 0 !important;
                    background-color: $color-white;
                    box-shadow: 0 0 10px rgba($color-black, 0.1);
                }
            }
        }
        textarea {
            width: 100%;
            outline: none;
            font-size: 16px;
            min-height: 37px;
            appearance: none;
            font-weight: 500;
            padding: 10px 0;
            word-wrap: break-word;
            color: $color-gray600;
            overflow-y: auto !important;
            background-color: transparent;
            border: 0;
            font-family: $font-family-base;
            &::placeholder {
                color: $color-gray400;
            }
        }
        button {
            border: 0;
            bottom: 50%;
            width: 45px;
            right: 4.5px;
            height: 40px;
            outline: none;
            display: flex;
            cursor: pointer;
            font-size: 14px;
            position: absolute;
            border-radius: $radius;
            align-items: center;
            justify-content: center;
            transform: translateY(50%);
            background-color: $color-primary;
            &:disabled {
                cursor: not-allowed;
                pointer-events: none;
                color: $color-gray200;
                border-color: $color-gray300;
                background-color: $color-gray300;
            }
        }
        ul {
            li {
                padding: 10px 10px;
                cursor: pointer;
                color: $color-primary;
                &:hover {
                    color: $color-black;
                    background-color: rgba($color-black, 0.05);
                }
            }
        }
        &.isUpdate {
            padding-right: 0;
            margin: 0;
            >form>div {
                min-height: 60px;
            }
            textarea {
                line-height: 1.4;
                padding-right: 10px;
            }
            button {
                padding: 0;
                width: auto;
                height: auto;
                cursor: pointer;
                transform: none;
                position: static;
                margin: 10px 20px 10px 0;
                background-color: transparent;
                &:nth-child(1) {
                    color: $color-gray500;
                }
                &:nth-child(2) {
                    color: $color-primary;
                }
            }
        }
    }
    &__more {
        width: 20px;
        height: 20px;
        display: flex;
        margin-left: auto;
        position: relative;
        align-items: center;
        >img {
            opacity: 0.5;
            cursor: pointer;
        }
        ul {
            z-index: 1;
            right: -2px;
            padding: 5px 0;
            min-width: 100px;
            position: absolute;
            border-radius: $radius;
            top: calc(100% + 2px);
            background-color: $color-white;
            box-shadow: 0 0 10px rgba($color-black, 0.1);
            &::before {
                right: 5px;
                content: "";
                bottom: 100%;
                position: absolute;
                border: 7px solid transparent;
                border-bottom-color: $color-white;
            }
            li {
                display: flex;
                cursor: pointer;
                font-weight: 500;
                padding: 5px 12px;
                align-items: center;
                white-space: nowrap;
                color: $color-gray500;
                transition: 0.3s ease-in-out;

                img {
                    width: 15px;
                    height: auto;
                    border-radius: 0;
                    margin-right: 5px;
                }
                span {
                    font-size: 12px;
                }
                &:hover {
                    background-color: rgba($color-black, 0.04);
                }
                &.isDelete {
                    color: $color-error;
                }
            }
        }
    }
}