@import './variables.scss';
.tiptap {
    .node-customBlock {
        margin-bottom: 30px;
        div[data-node-view-content] {
            width: 100%;
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                font-weight: 600;
                margin: 0 0 10px !important;
            }
            h1 {
                font-size: 26px !important;
                @include max-screen($mobile) {
                    font-size: 24px !important;
                }
            }
            h2 {
                font-size: 24px !important;
                @include max-screen($mobile) {
                    font-size: 22px !important;
                }
            }
            h3 {
                font-size: 20px !important;
            }
            h4 {
                font-size: 18px !important;
            }
            h5 {
                font-size: 16px !important;
            }
            h6 {
                font-size: 14px !important;
            }
            p {
                font-size: 14px !important;
                letter-spacing: 0.3px;
            }
            ul,
            ol {
                margin-left: 22px;
            }
            ol,
            ol li {
                list-style: decimal;
            }
            ul li,
            ul {
                list-style: disc;
            }
            blockquote {
                margin: 10px 0;
                padding: 15px 20px;
                font-style: italic;
                border-radius: 10px;
                position: relative;
                background-color: rgba($color-black, 0.02);
                border: 1px solid rgba($color-black, 0.1);
                &::before,
                &::after {
                    opacity: 0.2;
                    line-height: 1;
                    font-size: 25px;
                    position: absolute;
                    display: inline-block;
                }
                &::before {
                    top: 5px;
                    left: 5px;
                    content: '"';
                }
                &::after {
                    right: 15px;
                    bottom: -5px;
                    content: '"';
                }
            }

            // table
            &:has(table) {
                z-index: 0;
                position: relative;
                overflow-x: auto;
                border-radius: 12px;
                border: 1px solid lighten($color-black, 80.39);
                width: min-content;
                max-width: 100%;
                @include max-screen($mobile) {
                    max-width: calc(100% - 47px);
                    min-width: calc(100% - 47px);
                }
                &:has(.trigger-button[aria-expanded="true"]) {
                    overflow: hidden;
                }
                &::-webkit-scrollbar {
                    width: 0;
                }
                >div {
                    padding: 0;
                    min-width: 100%;
                }
                .column-resize-handle {
                    height: 100%;
                    background-color: rgba($color-primary-light, 0.5);
                    bottom: -2px;
                    position: absolute;
                    right: 0;
                    z-index: 7;
                    top: 0;
                    cursor: col-resize;
                    width: 6px;
                }
                table {
                    table-layout: fixed;
                    margin: 0 0 -1px -1px;
                    border-collapse: collapse;
                    background-color: lighten($color-black, 80.39);
                    tr {
                        th,
                        td {
                            padding: 0;
                            position: relative;
                            color: $color-gray600;
                            border: 0;

                            min-width: 120px;
                            width: 200px;
                            border-left: 1px solid lighten($color-black, 80.39);
                            border-bottom: 1px solid lighten($color-black, 80.39);
                            >div {
                                padding: 11px 0;
                                background-color: transparent !important;
                                >span>div {
                                    padding: 0 15px;
                                    display: block;
                                }
                            }
                            p {
                                hyphens: auto;
                                word-wrap: break-word;
                            }
                            &:last-child {
                                .collaboration-cursor__label {
                                    left: auto;
                                    right: -1px;
                                }
                                &::before {
                                    display: none;
                                }
                            }
                            &:first-child {
                                .collaboration-cursor__label {
                                    left: -1;
                                    right: auto;
                                }
                            }
                            &:has(.isViewer) {
                                pointer-events: none;
                            }
                            &.text-center {
                                vertical-align: middle;
                            }
                            // &.text-vertical {
                            //     >div {
                            //         width: 100%;
                            //         height: 100%;
                            //         padding: 0;
                            //         text-orientation: mixed;
                            //         writing-mode: vertical-rl;
                            //         >span {
                            //             width: 100%;
                            //             height: 100%;
                            //             >div {
                            //                 width: 100%;
                            //                 height: 100%;
                            //                 display: flex;
                            //                 padding: 11px 0;
                            //                 align-items: center;
                            //                 justify-content: center;
                            //                 transform: scale(-1);

                            //             }
                            //         }
                            //     }
                            //     .column-resize-handle {
                            //         right: auto;
                            //         left: -1px;
                            //         height: 100%;
                            //     }
                            // }
                        }
                        th {
                            font-size: 16px;
                            font-weight: 700;
                            text-align: left;
                            background-color: #F5F5F5;
                            &.selectedCell {
                                background-color: lighten($color-light, 2%);
                            }
                        }
                        td {
                            font-size: 14px;
                            vertical-align: top;
                            background-color: $color-white;
                            &.selectedCell {
                                background-color: lighten($color-light, 3%);
                            }
                        }
                        &:last-child {
                            th,
                            td {
                                border-bottom: 0;
                            }
                        }
                    }
                }
            }
            // task list 
            &:has(ul[data-type="taskList"]):not(:has(table)) {
                width: 100%;
                padding: 15px;
                letter-spacing: 0.3px;
                border-radius: 12px;
                border: 1.5px solid $color-dark;
                background-color: rgba($color-dark, 0.1);
            }
            &:has(ul[data-type="taskList"]) {
                p {
                    margin: 0 0 10px;
                    font-size: 14px;
                    &[contenteditable="false"] {
                        user-select: none;
                        pointer-events: none;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                ul {
                    margin: 0 0 10px 5px;
                    li {
                        display: flex;
                        margin: 0 0 5px;
                        align-items: center;
                        position: relative;
                        p {
                            margin-bottom: 0;
                        }
                        label {
                            display: block;
                            padding-left: 15px;
                            &::before {
                                top: 5px;
                                left: 0;
                                content: '';
                                width: 8px;
                                height: 8px;
                                display: block;
                                cursor: pointer;
                                position: absolute;
                                border: 1px solid $color-black;
                            }
                            input {
                                top: 4px;
                                left: 7px;
                                opacity: 0;
                                cursor: pointer;
                                position: absolute;
                            }
                        }
                        div {
                            min-width: 30px;
                        }
                        &[data-checked="true"] {
                            label {
                                &::before {
                                    background-color: $color-black;
                                }
                            }
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                        li {
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
            // rich editor
            .richtext-editor {
                width: 100%;
                padding: 15px;
                min-height: 150px;
                border-radius: 10px;
                line-height: 1.5;
                color: $color-gray600;
                background-color: $color-white;
                border: 1px solid rgba($color-gray700, 0.15);
                &:hover {
                    box-shadow: 0 0 0 1px rgba($color-gray700, 0.15);
                }
                &__link {
                    padding: 10px;
                    min-width: 280px;
                    position: relative;
                    border-radius: 10px;
                    background-color: $color-white;
                    box-shadow: 0 0 5px rgba($color-black, 0.1);
                    input {
                        height: 30px;
                        outline: none;
                        appearance: none;
                        margin-right: 5px;
                        border-radius: 5px;
                        padding: 3px 10px;
                        width: calc(100% - 62px);
                        border: 1px solid $color-gray300;
                    }
                    button {
                        height: 30px;
                        font-size: 12px;
                        padding: 3px 10px;
                        border-radius: 5px;
                    }
                }
                a {
                    text-decoration: underline;
                }
                &.focusOut {
                    user-select: none;
                    pointer-events: none;
                }
                &__loader {
                    width: 100%;
                }
                .tippy-box {
                    &:has(.isShow) {
                        .tippy-arrow {
                            border: 8px solid transparent;
                            border-top-color: $color-gray300;
                        }
                    }
                }
                p:first-child[data-placeholder] {
                    &::before {
                        content: attr(data-placeholder);
                        float: left;
                        height: 0;
                        color: $color-gray400;
                        pointer-events: none;
                        font-style: italic;
                    }
                }
                &:has(.is-empty+p) {
                    p {
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
.ProseMirror-focused .active-node {
    .richtext-editor {
        border-color: $color-primary !important;
    }
}