.button {
    border: 0;
    z-index: 1;
    height: 40px;
    outline: none;
    font-size: 16px;
    cursor: pointer;
    appearance: none;
    overflow: hidden;
    font-weight: 500;
    user-select: none;
    padding: 5px 15px;
    position: relative;
    color: $color-white;
    align-items: center;
    border-radius: 8px;
    display: inline-flex;
    letter-spacing: 0.32px;
    justify-content: center;
    background: $color-primary;
    transition: 0.3s ease-in-out;
    font-family: $font-family-base;
    &:hover {
        background-color: darken($color-primary, 5%);
    }
    &+.button {
        margin-left: 10px;
    }
    &__loader {
        position: relative;
        margin-left: 5px;
        span {
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-left: 3px;
            background: $color-gray200;
            animation: wave 1.3s linear infinite;
            &:nth-child(2) {
                animation-delay: -1.1s;
            }

            &:nth-child(3) {
                animation-delay: -0.9s;
            }
        }
    }
    &.outline {
        color: $color-black;
        background: transparent;
        border: 1px solid $color-dark;
        &:hover {
            color: $color-white;
            background-color: $color-dark;
        }
    }
    &.outline-gray {
        color: $color-black;
        background: transparent;
        border: 2px solid $color-gray400;
        &:hover {
            color: $color-white;
            background-color: $color-gray400;
        }
    }
    &.md {
        @include max-screen($mobile) {
            height: 45px;
            font-size: 14px;
        }
    }
    &.sm {
        height: 45px;
        font-size: 14px;
        padding: 5px 18px;
    }
    &:disabled {
        cursor: not-allowed;
        pointer-events: none;
        color: $color-gray200;
        border-color: $color-gray300;
        background-color: $color-gray300;
    }
    &.isFull {
        width: 100%;
    }
    @keyframes wave {
        0%,
        60%,
        100% {
            transform: initial;
        }
        30% {
            transform: translateY(-4px);
        }
    }
}