@import '../../../../assets/scss/variables.scss';
.addImage {
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 2;
    position: absolute;
    padding-bottom: 10px;
    @include max-screen($mobile) {
        left: -50px;
        width: calc(100% + 50px);
    }
    p {
        text-align: center;
        margin: 20px 0 0;
    }
    &__outer {
        border-radius: 10px;
        background-color: $color-white;
        box-shadow: 0 10px 20px rgba($color-black, 0.2);
    }
    &__content {
        padding: 20px;
    }
    &__tab {
        position: relative;
        border-bottom: 1px solid rgba($color-black, 0.1);
        button {
            border: 0;
            font-size: 16px;
            cursor: pointer;
            position: relative;
            padding: 14px 20px;
            background-color: transparent;
            @include max-screen($mobile) {
                font-size: 13px;
                padding: 14px 10px;
            }
            &::after {
                left: 0;
                width: 100%;
                content: '';
                height: 2px;
                bottom: -1px;
                opacity: 0;
                position: absolute;
                background-color: $color-primary;
            }
            &.active {
                &::after {
                    opacity: 1;
                }
            }
        }
    }
    &__input {
        input {
            width: 100%;
            height: 50px;
            outline: none;
            font-size: 16px;
            appearance: none;
            font-weight: 500;
            padding: 8px 16px;
            border-radius: 12px;
            color: $color-gray600;
            background-color: transparent;
            border: 1.5px solid $color-gray300;
            font-family: $font-family-base;
            &::placeholder {
                color: $color-gray400;
            }
        }
    }
    &__upload {
        width: 100%;
        margin: 0 auto;
        min-height: 92px;
        cursor: pointer;
        position: relative;
        padding: 13px 10px;
        font-size: 16px;
        border-radius: 10px;
        border: 1px dashed $color-gray600;
        input {
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            cursor: pointer;
            position: absolute;
        }
        &:hover {
            background-color: rgba($color-black, 0.05);
        }
        img {
            width: 50px;
        }
        span {
            font-size: 12px;
            &.isMobile {
                @include min-screen($tablet) {
                    display: none;
                }
            }
            &.isDesktop {
                @include max-screen($tablet) {
                    display: none;
                }
            }
        }
    }
    &__image {
        width: 100%;
        picture {
            display: inline-block;
            position: relative;
            >img {
                display: block;
                margin: 0 auto;
                min-width: 100px;
                min-height: 100px;
                max-height: 300px;
            }
        }
        button {
            top: -5px;
            right: -5px;
            z-index: 1;
            width: 20px;
            padding: 2px;
            cursor: pointer;
            position: absolute;
            border-radius: 50%;
            background-color: $color-white;
            border: 1px solid rgba($color-black, 0.1);
            box-shadow: 0 0 3px rgba($color-black, 0.1);
        }
    }
    &__info,
    &__error {
        font-size: 12px;
        margin: 5px 0 0 3px;
        color: $color-error;
        &.isCenter {
            text-align: center;
        }
    }
    &__info {
        margin-top: 10px;
        color: $color-primary;
    }
    &__footer {
        padding-top: 20px;
    }
}