.select-menu,
.select-dropdown {
    &__control {
        box-shadow: none !important;
        min-height: 40px !important;
        border-radius: 8px !important;
        border: 1.5px solid $color-gray300 !important;
        background-color: transparent !important;
        &--is-focused {
            border-color: $color-gray600 !important;
        }
    }
    &__value-container {
        font-weight: 500;
        color: $color-gray600;
        font-size: 16px !important;
        padding-left: 13px !important;
        padding-right: 13px !important;
    }
    &__single-value {
        font-weight: 500;
        color: $color-gray600;
        font-size: 16px !important;
        font-family: $font-family-base !important;
    }
    &__placeholder {
        letter-spacing: 0.32px;
        color: $color-gray400 !important;
    }
    &__indicator-separator {
        display: none;
    }
    &__dropdown-indicator {
        position: relative;
        &::before {
            top: 50%;
            left: 50%;
            content: "";
            position: absolute;
            border: 5px solid transparent;
            border-top: 7px solid $color-black;
            transform: translate(-70%, -20%);
        }
        svg {
            opacity: 0;
        }
    }
    &__menu {
        min-width: 100%;
        margin: 0 !important;
        width: auto !important;
        border-radius: 10px !important;
        border: 1px solid $color-gray300;
        box-shadow: 0 4px 11px rgba($color-black, 0.1) !important;
        @include max-screen($mobile) {
            max-width: calc(100vw - 50px);
        }
        .select-dropdown__option {
            @include max-screen($mobile) {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &.is-wrap {
                white-space: normal;
            }
        }
        &.menuAlignRight {
            right: 0;
            max-width: 700px;
            @include max-screen($mobile) {
                max-width: calc(100vw - 30px);
            }
            .select-dropdown__option {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    &__option {
        font-weight: 500;
        color: $color-black;
        white-space: nowrap;
        cursor: pointer !important;
        font-size: 14px !important;
        padding: 6px 15px !important;
        background-color: transparent !important;
    }
    &__option--is-selected {
        pointer-events: none;
        color: rgba($color-primary, 0.8) !important;
        // background-color: rgba($color-primary, 0.8) !important;
    }
    &__option--is-disabled {
        pointer-events: none;
    }
    &__menu-notice {
        white-space: nowrap;
    }
    &__menu-list {
        min-width: 290px;
    }
    &.is-active {
        .select-menu__control,
        .select-dropdown__control {
            border-color: $color-gray600 !important;
        }
    }
    &.isSimple {
        max-width: fit-content;
        .select-menu__menu,
        .select-dropdown__menu {
            width: auto;
            min-width: 100%;
        }
        .select-menu__option,
        .select-dropdown__option {
            white-space: nowrap;
            padding: 10px 12px !important;
        }
        .select-menu__dropdown-indicator,
        .select-dropdown__dropdown-indicator,
        .select-menu__value-container,
        .select-dropdown__value-container {
            padding: 0 !important;
        }
        .select-menu__placeholder,
        .select-dropdown__placeholder {
            margin: 0;
            font-size: 16px;
            cursor: pointer;
            font-weight: 400;
            color: $color-black;
            max-width: min-content;
        }
        .select-menu__control,
        .select-dropdown__control {
            min-height: 0 !important;
            border: 0 !important;
            input {
                grid-area: 1;
            }
        }
        .select-menu__dropdown-indicator,
        .select-dropdown__dropdown-indicator {
            width: 15px;
            cursor: pointer;
            &::before {
                left: auto;
                right: 0;
                transform: translateY(-20%);
            }
        }
    }
    &.isStatus {
        border-radius: 30px;
        padding: 3px 10px 3px 30px;
        background-color: rgba(var(--status-color), 0.2);
        &::before {
            top: 50%;
            left: 10px;
            content: '';
            width: 12px;
            height: 12px;
            position: absolute;
            border-radius: 50%;
            transform: translateY(-50%);
            border: 0.2px solid $color-gray600;
            background-color: rgba(var(--status-color), 1);
        }
        .select-menu__dropdown-indicator,
        .select-dropdown__dropdown-indicator {
            margin: 0 5px 0 20px;
        }
        .select-menu__single-value,
        .select-dropdown__single-value,
        .select-menu__input-container,
        .select-dropdown__input-container {
            font-size: 12px !important;
        }
    }
    &__menu-portal {
        z-index: 1000 !important;
        @include max-screen($mobile) {
            max-width: 100%;
        }
    }
    &.select-menu--is-disabled {
        .select-menu__indicator {
            opacity: 0.3;
        }
    }
}

.select-menu {
    &__menu-portal {
        transform: translateX(-35px);
        min-width: 110px;
    }
}