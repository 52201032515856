@import '../../../../assets/scss/variables.scss';
.socketFallback {
    text-align: center;
    padding: 30px 0 10px;
    p {
        margin-bottom: 20px;
    }
    strong {
        font-weight: 400;
        margin-bottom: 5px;
    }
    &__connecting {
        margin-bottom: 30px;
        span {
            width: 10px;
            height: 10px;
            margin: 0 8px;
            border-radius: 50%;
            display: inline-block;
            background: $color-primary;
            animation: connecting 1.3s linear infinite;
            &:nth-child(2) {
                animation-delay: -1.1s;
            }
            &:nth-child(3) {
                animation-delay: -0.9s;
            }
        }
    }
    @keyframes connecting {
        0%,
        60%,
        100% {
            opacity: 0;
        }
        30% {
            opacity: 1;
        }
    }
}