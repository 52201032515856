@import '../../assets/scss/variables.scss';
.notification {
    padding: 30px;
    @include max-screen($tablet) {
        padding: 25px 15px;
    }
    &__header {
        margin-bottom: 32px;
        @include max-screen($mobile) {
            margin-bottom: 20px;
        }
        h1 {
            font-size: 24px;
            width: calc(100% - 120px);
            @include max-screen($mobile) {
                font-size: 22px;
            }
        }
        button {
            height: 50px;
        }
    }
    &__content {
        padding: 0 20px;
        border-radius: 16px;
        background-color: $color-gray-shade;

        ul {
            li {
                cursor: pointer;
                position: relative;
                padding: 20px 30px 20px 0;
                border-bottom: 1px solid $color-white;
                @include max-screen($mobile) {
                    font-size: 12px;
                    padding: 15px 30px 15px 0;
                }
                &:last-child {
                    border-bottom: 0;
                }
                picture {
                    width: 50px;
                    height: 50px;
                    display: flex;
                    padding: 14px;
                    border-radius: 50%;
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    background-color: $color-light;
                    @include max-screen($mobile) {
                        width: 40px;
                        height: 40px;
                        padding: 12px;
                    }
                    img {
                        max-height: 100%;
                    }
                }
                aside {
                    padding-left: 10px;
                    width: calc(100% - 50px);
                    @include max-screen($mobile) {
                        width: calc(100% - 40px);
                    }
                }
                p {
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 2px;
                    color: $color-black;
                    @include max-screen($mobile) {
                        font-size: 14px;
                    }
                }
                span {
                    display: block;
                    font-size: 14px;
                    color: lighten($color-black, 55.69);
                    @include max-screen($mobile) {
                        font-size: 12px;
                    }
                }
                button {
                    right: 0;
                    border: 0;
                    top: 32px;
                    width: 25px;
                    outline: none;
                    cursor: pointer;
                    position: absolute;
                    background-color: transparent;
                }
            }
        }
    }
    &__nodata {
        text-align: center;
        padding: 20px 0;
    }
}