@import '../../../../assets/scss/variables.scss';
.richTextEditor {
    &__link {
        padding: 10px;
        min-width: 280px;
        position: relative;
        border-radius: 10px;
        background-color: $color-white;
        box-shadow: 0 0 5px rgba($color-black, 0.1);
        input {
            height: 30px;
            outline: none;
            appearance: none;
            margin-right: 5px;
            border-radius: 5px;
            padding: 3px 10px;
            width: calc(100% - 62px);
            border: 1px solid $color-gray300;
        }
        button {
            height: 30px;
            font-size: 12px;
            padding: 3px 10px;
            border-radius: 5px;
        }
    }
}