* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-wrap: break-word;
}

body {
    color: $color-black;
    font-size: $font-size-base;
    overscroll-behavior-y: none;
    font-family: $font-family-base;
    line-height: $line-height-base;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: $color-white;
    &:has(.trigger-button[aria-expanded="true"]),
    &.scroll-disabled {
        overflow: hidden;
    }
}
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-track {
    background: rgba($color-black, 0.1);
}
::-webkit-scrollbar-thumb {
    background: rgba($color-black, 0.2);
}
a {
    outline: none;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        color: $color-primary;
    }
}

img {
    height: auto;
    display: block;
    max-width: 100%;
}

:focus {
    outline: none;
}

::-ms-expand {
    display: none;
}

ul {
    list-style: none;
    li {
        list-style: none;
    }
}

// collaboration cursor
.collaboration-cursor {
    &__caret {
        margin-left: -1px;
        margin-right: -1px;
        position: relative;
        word-break: normal;
        pointer-events: none;
        border-left: 1px solid $color-gray600;
        border-right: 1px solid $color-gray600;
    }
    &__label {
        left: -1px;
        top: -1.4em;
        font-size: 12px;
        font-weight: 600;
        user-select: none;
        font-style: normal;
        position: absolute;
        line-height: normal;
        white-space: nowrap;
        color: $color-gray600;
        padding: 0.1rem 0.3rem;
        border-radius: 3px 3px 3px 0;
    }
}
tr:has(.notSelected),
th:has(.notSelected),
td:has(.notSelected) {
    user-select: none !important;
    pointer-events: none !important;
    &::after {
        top: 0;
        left: 0;
        z-index: 1;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
    }
    &::before {
        opacity: 0;
    }
    >div {
        background-color: transparent !important;
    }
    label {
        display: none;
    }
}

[contenteditable="true"] {
    caret-color: currentColor;
}

div:not([contenteditable="true"]) {
    >.collaboration-cursor__caret {
        display: none;
    }
}