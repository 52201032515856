@import '../../assets/scss/variables.scss';
.teamMember {
    padding: 10px 0;
    &__header {
        aside {
            width: calc(59% - 5px);
            @include max-screen($mobile) {
                width: calc(100% - 65px);
            }
            &:nth-child(2) {
                max-width: calc(41% - 5px);
                @include max-screen($mobile) {
                    max-width: 50px;
                }
            }
            &.isFull {
                width: 100%;
            }
            a {
                width: 40px;
                height: 40px;
                display: none;
                font-size: 30px;
                line-height: 1;
                align-items: center;
                border-radius: 8px;
                color: $color-white;
                justify-content: center;
                background-color: $color-primary;
                @include max-screen($mobile) {
                    display: flex;
                }
            }
            button {
                @include max-screen($mobile) {
                    display: none;
                }
            }
        }
        input {
            @include min-screen($mobile) {
                height: 50px;
            }
        }
        button {
            height: 50px;
            font-size: 14px;
        }
    }
    &__list {
        padding-top: 20px;
    }
    &__item {
        padding: 16px 0;
        border-bottom: 1px solid $color-gray300;
        p {
            hyphens: auto;
            font-size: 16px;
            font-weight: 500;
            color: $color-gray600;
            word-wrap: break-word;
            width: calc(100% - 90px);
        }
        aside {
            :global {
                .select-dropdown__value-container {
                    cursor: pointer;
                }
                .select-dropdown__single-value {
                    font-size: 14px !important;
                }
                .select-dropdown__dropdown-indicator {
                    &::before {
                        border-width: 5px;
                    }
                }
            }
        }
    }
    &__nodata {
        padding: 30px 20px;
        text-align: center;
    }
}