@import "../../assets/scss/variables.scss";
.inputSearch {
    position: relative;
    input {
        width: 100%;
        height: 40px;
        outline: none;
        font-size: 16px;
        appearance: none;
        font-weight: 500;
        border-radius: 8px;
        color: $color-black;
        padding: 11px 15px 11px 50px;
        font-family: $font-family-base;
        border: 1px solid $color-gray300;
        @include max-screen($mobile) {
            font-size: 14px;
            padding: 11px 15px 11px 40px;
        }
        &::placeholder {
            color: $color-gray500;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &[type="number"] {
            -moz-appearance: textfield;
        }
    }
    img {
        top: 50%;
        left: 12px;
        width: 28px;
        cursor: pointer;
        position: absolute;
        transform: translateY(-50%);
        @include max-screen($mobile) {
            width: 20px;
        }
    }
}