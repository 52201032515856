@import "../../assets/scss/variables.scss";
.dashboard {
    padding-left: 245px;
    @include max-screen($tablet) {
        padding-left: 0;
    }
    &__header {
        margin-bottom: 24px;
        @include max-screen($mobile) {
            flex-direction: column-reverse;
        }
        button {
            @include max-screen($mobile) {
                margin-left: auto;
            }
            img {
                display: block;
                margin: 0 8px -1px 0;
                @include max-screen($mobile) {
                    margin-right: 3px;
                    margin-left: -5px;
                }
            }
        }
    }
    &__tab {
        @include max-screen($mobile) {
            width: 100%;
            margin-top: 22px;
        }
    }
    &__content {
        padding: 30px;
        @include max-screen($tablet) {
            padding: 25px 15px;
        }
    }
}