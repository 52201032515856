@import '../../../../assets/scss/variables.scss';
.sectionEditor {
    margin-bottom: 30px;
    >h3 {
        font-size: 18px;
        line-height: 1.5;
        font-weight: 600;
        margin-bottom: 16px;
        color: $color-gray600;
        @include max-screen($mobile) {
            line-height: 1.2;
        }
        &.level_1 {
            font-size: 24px;
            @include max-screen($mobile) {
                font-size: 20px;
            }
        }
        span {
            display: inline;
            vertical-align: middle;
        }
        img {
            width: 20px;
            min-width: 20px;
            cursor: pointer;
            margin-left: 5px;
            display: inline-block;
            vertical-align: middle;
        }
        picture {
            margin: 0 10px;
            img {
                opacity: 1;
                height: 20px;
                cursor: inherit;
                object-fit: cover;
                border-radius: 50%;
                margin: 0 -10px 0 0;
                background-color: $color-white;
                border: 0.5px solid $color-gray400;
            }
            >div {
                position: static;
                margin: 0 -10px 0 0;
                display: inline-flex;
                vertical-align: middle;
                background-color: $color-white;
            }
            &:empty {
                display: none;
            }
        }
    }
}