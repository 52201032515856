@import "./variables.scss";
.auth {
    padding: 84px 30px 30px;
    background-color: $color-gray200;
    min-height: calc(100dvh - 63px);
    @include max-screen($desktop) {
        padding: 60px 30px 30px;
    }
    @include max-screen($tablet) {
        padding: 40px 20px 20px;
    }
    @include max-screen($mobile) {
        padding: 20px 15px;
    }
    h1 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 32px;
        letter-spacing: 0.48px;
        @include max-screen($mobile) {
            margin-bottom: 15px;
        }
    }
    h4 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 14px;
        letter-spacing: 0.4px;
        @include max-screen($mobile) {
            font-size: 18px;
        }
        &.isInfoTitle {
            padding-top: 12px;
        }
    }
    p {
        font-size: 16px;
        margin-bottom: 30px;
    }
    button {
        margin-top: 20px;
    }
    &__wrap {
        width: 100%;
        margin: 0 auto;
        max-width: 400px;
    }
    &__forgot,
    &__infoText {
        font-size: 16px;
        margin-top: 17px;
        text-align: center;
        color: $color-gray500;
        a {
            font-weight: 500;
            color: $color-black;
            letter-spacing: 0.32px;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
    }
    &__forgot {
        margin-top: -8px;
        text-align: right;
    }
    &.authChange {
        padding: 0;
        min-height: 0;
    }
}