@import './variables.scss';
.editorMenu {
    padding: 8px 12px;
    background-color: $color-light;
    button {
        border: 0;
        outline: none;
        cursor: pointer;
        appearance: none;
        background-color: transparent;
    }
    &__typo {
        position: relative;
        >button {
            height: 24px;
            display: flex;
            min-width: 47px;
            font-size: 14px;
            font-weight: 500;
            align-items: center;
            color: $color-gray600;
            &::after {
                content: '';
                margin-left: 3px;
                transform: translateY(3px);
                border: 4px solid transparent;
                border-top-color: currentColor;
            }
        }
    }
    &__dropdown {
        left: 70%;
        top: 100%;
        z-index: 1;
        width: 200px;
        padding: 5px 0;
        position: absolute;
        border-radius: 10px;
        transform: translateX(-50%);
        background-color: $color-white;
        box-shadow: 0 0 10px rgba($color-black, 0.1);
        @include max-screen($tablet) {
            left: calc(50% + 60px);
        }
        button {
            width: 100%;
            display: block;
            font-size: 16px;
            text-align: left;
            padding: 8px 15px;
            color: $color-black;
            text-transform: capitalize;
            @include max-screen($mobile) {
                font-size: 14px;
            }
            &+button {
                border-top: 1px solid rgba($color-black, 0.02);
            }
            &:hover {
                color: rgba($color-black, 0.4);
            }
        }
    }
    &__group {
        height: 24px;
        padding: 0 10px;
        align-items: center;
        justify-content: center;
        border-left: 1px solid $color-gray300;
        @include max-screen($mobile) {
            padding: 0 5px;
        }
        button {
            width: 20px;
            height: 17px;
            display: flex;
            margin: 0 3px;
            align-items: center;
            justify-content: center;
            @include max-screen($mobile) {
                width: 18px;
                padding: 1px;
                height: 15px;
            }
            &.isActive {
                background-color: rgba($color-white, 0.5);
            }
            img {
                max-height: 100%;
            }
        }
    }
    &__groupItem {
        position: relative;
        &:hover {
            .editorMenu__groupInfo {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &__groupInfo {
        left: 50%;
        opacity: 0;
        font-size: 10px;
        line-height: 1.1;
        text-align: left;
        padding: 3px 8px;
        border-radius: 4px;
        visibility: hidden;
        position: absolute;
        color: $color-white;
        bottom: calc(100% + 5px);
        transform: translateX(-50%);
        transition: 0.3s ease-in-out;
        background-color: $color-gray600;
        @include max-screen($mobile) {
            display: none;
        }
        strong {
            display: block;
            margin-bottom: 2px;
            white-space: nowrap;
        }
        span {
            opacity: 0.5;
        }
    }
    &__color {
        margin: 0 4px;
        display: flex;
        font-size: 14px;
        line-height: 1;
        cursor: pointer;
        overflow: hidden;
        text-align: center;
        position: relative;
        align-items: center;
        flex-direction: column;
        span {
            width: 25px;
            height: 4px;
            display: block;
            margin-top: 1px;
            background-color: $color-primary;
        }
        img {
            width: 15px;
            margin: 0 auto;
        }
        input {
            top: 0;
            left: 0;
            opacity: 0;
            cursor: pointer;
            position: absolute;
        }
    }
}