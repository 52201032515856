@import '../../../../assets/scss/variables.scss';
.analytics {
    margin-bottom: 30px;
    &:last-of-type {
        margin-bottom: 0;
    }
    h4 {
        font-size: 18px;
        font-weight: 600;
        padding: 0 15px 5px;
        color: $color-primary;
        letter-spacing: 0.4px;
        @include max-screen($mobile) {
            width: 100%;
            padding: 0 15px 5px;
        }
    }
    &__content {
        padding-top: 15px;
        border-radius: 10px;
        margin-bottom: 10px;
        background-color: $color-dark;
        border: 1px solid $color-gray700;
    }
    &__scroll {
        padding: 0 15px 10px;
    }
    &__row {
        margin: 0 -15px;
    }
    &__item {
        width: 20%;
        margin: 5px 0 0;
        font-size: 14px;
        padding: 5px 15px;
        color: $color-white;
        border-left: 1px solid rgba($color-black, 0.05);
        @include max-screen($desktop-xl) {
            width: 25%;
        }
        @include max-screen($desktop) {
            width: 33.3%;
        }
        @include max-screen($tablet) {
            width: 50%;
        }
        @include max-screen($mobile) {
            width: 100%;
            padding: 5px 15px;
        }
        &:first-child {
            border: 0;
        }
        strong {
            display: block;
            letter-spacing: 0.4px;
            margin-bottom: 5px;
        }
        p {
            font-style: italic;
        }
    }
    &__btn {
        border: 0;
        height: 44px;
        outline: none;
        cursor: pointer;
        font-size: 16px;
        padding: 5px 17px;
        align-items: center;
        border-radius: 24px;
        color: $color-white;
        display: inline-flex;
        background-color: $color-primary;
        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin: 0 10px 0 -9px;
        }
    }
}