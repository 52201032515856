@import '../../assets/scss/variables.scss';
.addProject {
    @include min-screen($tablet) {
        padding-bottom: 10px;
    }
    &__step {
        margin-bottom: 28px;
    }
    &__stepItem {
        span {
            width: 24px;
            height: 24px;
            display: flex;
            font-size: 12px;
            border-radius: 50%;
            align-items: center;
            color: $color-gray500;
            justify-content: center;
            border: 1px solid $color-gray500;
        }
        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 1.2;
            padding-left: 8px;
            color: $color-gray500;
            width: calc(100% - 24px);
            @include max-screen($mobile) {
                display: none;
            }
        }
        &.previous,
        &.active {
            span {
                color: $color-black;
                border-color: $color-success-light;
                background-color: $color-success-light;
            }
            p {
                color: $color-black;
            }
        }
        &.previous {
            span {
                font-size: 0;
                border-color: $color-primary;
                background-color: $color-primary;
                @include background-image(done, svg, center, no-repeat, 16px)
            }
        }
    }
    &__stepDivider {
        flex: auto;
        height: 1px;
        margin: 0 15px;
        background-color: $color-gray500;
    }
    &__row {
        margin: 0 -10px;
    }
    &__col {
        width: 50%;
        padding: 0 10px;
        @include max-screen($mobile) {
            width: 100%;
        }
    }
    &__footer {
        padding-top: 28px;
        @include max-screen($mobile) {
            padding-top: 15px;
        }
    }
    &__continue {
        flex: auto;
        padding-left: 14px;
    }
}