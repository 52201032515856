@import "../../assets/scss/variables.scss";
.projects {
    &__tab {
        margin-bottom: 12px;
    }
    &__action {
        width: 100%;
        padding-bottom: 10px;
    }
    &__info {
        font-size: 18px;
        text-align: center;
    }
}