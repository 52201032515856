@import "../../assets/scss/variables.scss";
.NotFoundPage {
    padding: 20px 15px;
    text-align: center;
    min-height: calc(100dvh - 91px);
    @include max-screen($desktop) {
        min-height: calc(100dvh - 83px);
    }
    @include max-screen($tablet) {
        min-height: calc(100dvh - 75px);
    }
    @include max-screen($mobile) {
        min-height: calc(100dvh - 65px);
    }
    article {
        max-width: 600px;
        margin: 0 auto;
    }
    strong {
        display: block;
        font-size: 150px;
        line-height: 1.1;
        color: $color-primary;
        @include max-screen($mobile) {
            font-size: 100px;
        }
    }
    h1 {
        font-size: 30px;
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 20px;
    }
    button {
        min-width: 150px;
    }
}
