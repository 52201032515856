@import "../../assets/scss/variables.scss";
.dropdownField {
    margin-bottom: 14px;
    :global .select-dropdown {
        margin-bottom: 0;
    }
    &__label {
        display: block;
        font-size: 14px;
        margin-bottom: 5px;
    }
    &__error {
        padding: 0 3px;
        font-size: 12px;
        margin-top: 3px;
        display: block;
        color: $color-error;
    }
    &.isDisable {
        opacity: 0.5;
        pointer-events: none;
    }
    &.noSpace {
        margin-bottom: 0;
    }
}