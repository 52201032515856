@import "../../assets/scss/variables.scss";
.table {
    overflow-x: auto;
    table {
        width: 100%;
        text-align: left;
        min-width: max-content;
        letter-spacing: 0.28px;
        border-collapse: collapse;
        tr {
            th,
            td {
                font-size: 14px;
                font-weight: 500;
                padding: 15px 20px;
                color: $color-black;
                strong {
                    font-weight: 600;
                }
            }
        }
        thead {
            tr {
                th {
                    border-bottom: 1px solid lighten($color-black, 71.76);
                    a {
                        display: block;
                        cursor: pointer;
                        margin: 0 0 -5px 5px;
                        &::before {
                            content: '';
                            height: 0;
                            display: inline-block;
                            border: 4px solid transparent;
                            border-top-color: $color-black;
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                position: relative;

                td {
                    cursor: pointer;
                    z-index: 1;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    text-transform: capitalize;
                    @include max-screen($desktop) {
                        padding-top: 26px;
                        padding-bottom: 26px;
                    }

                    &:first-child {
                        &::before {
                            left: 0;
                            top: 6px;
                            width: 100%;
                            z-index: -1;
                            content: "";
                            bottom: 6px;
                            position: absolute;
                            transition: 0.3s ease-in-out;
                            background-color: $color-gray-shade;
                        }
                        &::before {
                            border-radius: $radius;
                        }
                    }
                }
                &:first-child {
                    td {
                        padding-top: 26px;
                        &::before {
                            top: 12px;
                        }
                    }
                }
                &:hover {
                    td {
                        &::before {
                            box-shadow: 0 3px 5px rgba($color-black, 0.1);
                            background-color: $color-primary-shade;
                        }
                    }
                }
            }
        }
    }
    img {
        &.actionBtn {
            cursor: pointer;
        }
    }
    &__status {
        height: 29px;
        font-size: 14px;
        font-weight: 500;
        padding: 3px 12px;
        align-items: center;
        border-radius: 50px;
        display: inline-flex;
        justify-content: center;
        background-color: $color-state-progress;
        &::before {
            content: '';
            width: 12px;
            height: 12px;
            margin-right: 5px;
            border-radius: 50%;
        }
        &.isProgress {
            background-color: $color-state-progress;
            &::before {
                background-color: darken($color-state-progress, 25%);
            }
        }
        &.isOpen {
            background-color: $color-state-open;
            &::before {
                background-color: darken($color-state-open, 25%);
            }
        }
        &.isCompleted {
            background-color: $color-state-completed;
            &::before {
                background-color: darken($color-state-completed, 25%);
            }
        }
    }
    &__nodata {
        padding: 24px 0;
        margin-top: 10px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: $color-gray500;
        letter-spacing: 0.32px;
        border-top: 1px solid lighten($color-black, 71.76);
        strong {
            color: rgba($color-black, 0.6);
        }
    }
}