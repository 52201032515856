@import '../../assets/scss/variables.scss';
.account {
    padding: 32px 30px;
    align-items: flex-start;
    @include max-screen($desktop) {
        padding: 30px;
    }
    @include max-screen($tablet) {
        padding: 20px;
    }
    @include max-screen($mobile) {
        padding: 20px 15px;
    }
    &__card {
        width: 271px;
        line-height: 1.2;
        text-align: center;
        padding: 50px 15px;
        border-radius: $radius;
        background-color: $color-gray-shade;
        @include max-screen($desktop) {
            padding: 30px 15px;
        }
        @include max-screen($tablet) {
            width: 100%;
        }
        @include max-screen($mobile) {
            padding: 20px 15px;
        }

        picture {
            width: 150px;
            height: 150px;
            display: block;
            position: relative;
            margin: 0 auto 10px;
            >img {
                width: 150px;
                height: 150px;
                object-fit: cover;
                border-radius: 50%;
                border: 0.5px solid $color-gray300;
            }
            input {
                display: none;
            }
            label {
                z-index: 1;
                right: 6px;
                width: 51px;
                bottom: -8px;
                height: 51px;
                display: flex;
                cursor: pointer;
                position: absolute;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                border: 2px solid $color-white;
                background-color: lighten($color-black, 60.00);
            }
            &:hover {
                .account__remove {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        strong {
            display: block;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 5px;
            letter-spacing: 0.3px;
        }
        span {
            color: $color-gray600;
            letter-spacing: 0.3px;
        }
    }
    &__remove {
        inset: 0;
        display: flex;
        border-radius: 50%;
        align-items: center;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        transition: 0.3s ease-in-out;
        justify-content: center;
        background-color: rgba($color-gray500, 0.5);
        img {
            width: 25px;
            cursor: pointer;
        }
    }
    &__loader {
        inset: 0;
        overflow: hidden;
        border-radius: 50%;
        position: absolute;
    }
    &__content {
        padding: 50px;
        margin-left: 63px;
        border-radius: $radius;
        width: calc(100% - 334px);
        background-color: $color-gray-shade;
        @include max-screen($desktop) {
            padding: 25px;
            margin-left: 30px;
            width: calc(100% - 301px);
        }
        @include max-screen($tablet) {
            width: 100%;
            margin: 30px 0 0;
        }
        @include max-screen($mobile) {
            padding: 20px;
        }
        h4 {
            font-size: 24px;
            font-weight: 600;
            line-height: 1.2;
            margin-bottom: 42px;
            letter-spacing: 0.3px;
            @include max-screen($mobile) {
                margin-bottom: 20px;
            }
        }
    }
    &__item {
        margin-bottom: 20px;
        label {
            display: block;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.2;
            margin-bottom: 10px;
            letter-spacing: 0.3px;
        }
    }
    &__bottom {
        button {
            @include max-screen(575px) {
                width: 100%;
                margin: 15px 0 0 !important;
            }
            &:first-child {
                margin-top: 0 !important;
            }
        }
    }
}