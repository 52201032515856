@import '../../../../assets/scss/variables.scss';
.imageBlock {
    text-align: center;
    img {
        margin: 0 auto;
    }
    &__wrap {
        display: inline-block;
        position: relative;
    }
    &__resize {
        top: 50%;
        right: 3px;
        width: 5px;
        opacity: 0;
        height: 50px;
        max-height: 80%;
        cursor: ew-resize;
        position: absolute;
        border-radius: 10px;
        transform: translateY(-50%);
        transition: 0.3s ease-in-out;
        background-color: $color-primary;
        box-shadow: 0 0 3px $color-white;
    }
    &__uploadWrap {
        position: relative;
    }
    &__upload {
        cursor: pointer;
        font-size: 16px;
        padding: 10px 15px;
        border-radius: 10px;
        color: $color-primary;
        background-color: rgba($color-primary-lighten, 0.3);
        img {
            width: 40px;
            opacity: 0.7;
            margin: 0 10px 0 0;
        }
    }
}
:global .ProseMirror-focused .node-image {
    &.ProseMirror-selectednode {
        .isResize {
            opacity: 1;
        }
    }
}