@mixin max-screen($screenSize) {
    @media (max-width: $screenSize) {
        @content;
    }
}

@mixin min-screen($screenSize) {
    @media (min-width: ($screenSize+1)) {
        @content;
    }
}
@mixin max-height($screenSize) {
    @media (max-height: $screenSize) {
        @content;
    }
}
@mixin background-image($img, $ext, $pos, $repeat: no-repeat, $size: auto) {
    background-image: url(#{$imgPath}#{$img}.#{$ext});
    background-size: $size;
    background-position: $pos;
    background-repeat: $repeat;
}