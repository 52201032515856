@import '../../../assets/scss/variables.scss';
.headerCaption {
    display: flex;
    margin-right: auto;
    align-items: center;
    &__title {
        font-size: 24px;
        display: inline;
        font-weight: 600;
        vertical-align: middle;
        @include max-screen($mobile) {
            font-size: 20px;
        }
        span {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: calc(100vw - 370px);
            @include max-screen($mobile) {
                max-width: calc(100vw - 260px);
            }
        }
    }
    img {
        display: inline;
        margin-left: 10px;
        vertical-align: middle;
    }
    picture {
        margin: 0 10px;
        img {
            height: 25px;
            cursor: inherit;
            min-width: 25px;
            object-fit: cover;
            border-radius: 50%;
            margin: 0 -10px 0 0;
            background-color: $color-white;
            border: 0.5px solid $color-gray400;
        }
        >div {
            position: static;
            margin: 0 -10px 0 0;
            display: inline-flex;
            vertical-align: middle;
            background-color: $color-gray200;
        }
        &:empty {
            display: none;
        }
    }
}