@import '../../../../assets/scss/variables.scss';
.editor {
    &__loader {
        padding: 12px;
        border-radius: 10px;
        margin-bottom: 30px;
        border: 1px solid darken($color-light, 8%);
    }
    &__content {
        &.notEditable {
            pointer-events: none;
            position: relative;
            &::before {
                content: '';
                inset: 0;
                z-index: 1;
                position: absolute;
            }
        }
    }
}