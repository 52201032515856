@import './variables.scss';
.actionMenu {
    position: relative;
    &__dropwdown {
        top: 25px;
        z-index: 7;
        left: 100px;
        width: 210px;
        padding: 6px 0;
        user-select: none;
        position: absolute;
        border-radius: 10px;
        transform: translateX(-50%);
        background-color: $color-white;
        box-shadow: 0 1px 2px 1px rgba($color-black, 0.15);
        button {
            border: 0;
            width: 100%;
            display: flex;
            cursor: pointer;
            appearance: none;
            text-align: left;
            padding: 11px 12px;
            align-items: center;
            font-family: $font-family-base;
            background-color: transparent;
            span {
                width: 22px;
                img {
                    &.isTransh {
                        width: 16px;
                        margin: 0 auto;
                    }
                }
            }
            aside {
                padding-left: 12px;
                width: calc(100% - 22px);
            }
            strong {
                display: block;
                font-size: 12px;
                font-weight: 500;
                color: $color-gray600;
            }
            p {
                font-size: 10px;
                color: $color-gray500;
            }
            &:hover {
                background-color: rgba($color-black, 0.03);
            }
        }
    }
    &__action {
        align-self: flex-start;
    }
    &__add {
        width: 20px;
        cursor: pointer;
        margin-right: 3px;
        user-select: none;
    }
    &__move {
        margin-right: 5px;
        padding: 4px 5px;
        cursor: grab;
        border-radius: 4px;
        &:hover {
            background-color: rgba($color-black, 0.03);
        }
    }
    &__move,
    &__add {
        position: relative;
        &:hover {
            >div {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &__info {
        left: 50%;
        opacity: 0;
        font-size: 10px;
        line-height: 1.1;
        text-align: left;
        padding: 5px 8px;
        border-radius: 4px;
        visibility: hidden;
        position: absolute;
        color: $color-white;
        bottom: calc(100% + 5px);
        transform: translateX(-50%);
        transition: 0.3s ease-in-out;
        background-color: $color-gray600;
        @include max-screen($mobile) {
            display: none;
        }
        p {
            margin: 3px 0;
            display: flex;
            justify-content: center;
            white-space: nowrap;
        }
        strong {
            display: block;
            margin-right: 5px;
            white-space: nowrap;
        }
        span {
            opacity: 0.5;
        }
        &.isAdda {
            margin-left: 20px;
        }
    }
}