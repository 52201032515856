@import '../../assets/scss/variables.scss';
[data-tippy-root] {
    z-index: 1;
    .tippy-content {
        article {
            padding: 10px;
            font-size: 12px;
            border-radius: 10px;
            background-color: $color-white;
            box-shadow: 0 0 10px rgba($color-black, 0.3);
            p {
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .tippy-box {
        &:has(.isShow) {
            .tippy-arrow {
                border: 10px solid transparent;
                border-top-color: $color-white;
            }
        }
        &[data-placement="bottom"] {
            .tippy-arrow {
                bottom: 100%;
                border-top-color: transparent;
                border-bottom-color: $color-white;
            }
        }
    }
}