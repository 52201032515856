// colors
$color-primary: #FF6332;
$color-primary-secondary: #ec754f;
$color-primary-shade: #fdeae5;
$color-primary-light: #75A3A9;
$color-primary-lighten: #D2E4E8;
$color-black: #000;
$color-white: #fff;
$color-light: #EAF3F5;
$color-gray: #fafafa;
$color-gray-shade: #f5f5f5;
$color-gray-alt: #ECECEC;
$color-gray100: #f6f6f6;
$color-gray200: #f9f9f9;
$color-gray300: #dddddd;
$color-gray400: #a1a0a8;
$color-gray500: #949494;
$color-gray600: #303637;
$color-gray700: #333;
$color-error: #f00;
$color-success: #58926a;
$color-success-medium: #78B279;
$color-success-light: #AAEA85;
$color-warning: #f9f685;
$color-dark: #002433;

// state color
$color-state-open: #b4c6c7;
$color-state-progress: #e8f3d8;
$color-state-completed: #78b379;
// $color-state-review: #e6a5bc;

// base
$font-size-base: 14px;
$line-height-base: 1.4;
$font-family-base: 'Inter', '-apple-system, BlinkMacSystemFont, Roboto, sans-serif';
$radius: 8px;

// breakpoints
$small: 575px;
$mobile: 767px;
$tablet-sm: 991px;
$tablet: 1023px;
$desktop: 1199px;
$desktop-lg: 1366px;
$desktop-xl: 1439px;

// image path
$imgPath: "/assets/images/";

// mixins
@import "mixins";