@import "../../assets/scss/variables.scss";
.tabButton {
    overflow-x: auto;
    @include max-screen($mobile) {
        margin: 0 -15px;
        padding: 0 15px;
    }
    &__wrap {
        display: flex;
        min-width: max-content;
    }
    &__item {
        height: 40px;
        display: flex;
        cursor: pointer;
        font-size: 14px;
        min-width: 140px;
        font-weight: 500;
        padding: 5px 20px;
        color: $color-black;
        align-items: center;
        white-space: nowrap;
        letter-spacing: 0.28px;
        justify-content: center;
        border: 1px solid $color-primary;
        @include max-screen($desktop) {
            height: 50px;
            min-width: 120px;
        }
        @include max-screen($mobile) {
            height: 40px;
            min-width: 80px;
        }
        &+div {
            border-left: 0;
        }
        &:first-child {
            border-top-left-radius: $radius;
            border-bottom-left-radius: $radius;
        }
        &:last-child {
            border-top-right-radius: $radius;
            border-bottom-right-radius: $radius;
        }
        &.active {
            font-weight: 700;
            border-width: 1.5px;
            background-color: rgba($color-primary, 0.15);
        }
    }
    &.tabButtonFill {
        .tabButton__item {
            flex: 1;
        }
    }
}