.flex {
    display: flex;
}
.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}
.align-center {
    align-items: center;
}
.align-start {
    align-items: flex-start;
}
.align-end {
    align-items: flex-end;
}
.justify-between {
    justify-content: space-between;
}
.justify-center {
    justify-content: center;
}
.justify-end {
    justify-content: flex-end;
}
.flex-col {
    display: flex;
    flex-direction: column;
}