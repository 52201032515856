@import '../../../../assets/scss/variables.scss';
.sectionManagement {
    padding: 10px 0;
    &__item {
        margin-bottom: 24px;
        &:last-child {
            margin-bottom: 0;
            strong {
                align-self: flex-start;
                padding-top: 6px;
            }
        }
        strong {
            width: 135px;
            font-size: 16px;
            font-weight: 500;
            padding-right: 10px;
            letter-spacing: 0.3px;
            color: $color-gray500;
            @include max-screen($mobile) {
                width: 95px;
            }
        }
        aside {
            width: calc(100% - 135px);
            @include max-screen($mobile) {
                width: calc(100% - 95px);
            }
        }
        label {
            cursor: pointer;
            user-select: none;
            font-size: 16px;
            position: relative;
            font-weight: 600;
            color: $color-gray500;
        }
        input[type="date"] {
            border: 0;
            outline: none;
            font-size: 0;
            opacity: 0;
            left: 0;
            top: 100%;
            cursor: pointer;
            appearance: none;
            color: transparent;
            position: absolute;
            background-color: transparent;
            &::placeholder {
                color: transparent;
            }
        }
    }
    &__userList {
        margin: 0 -12px;
        @include max-screen($mobile) {
            margin: 0 -5px;
        }
    }
    &__user {
        padding: 5px 12px;
        @include max-screen($mobile) {
            padding: 5px;
        }
        p {
            font-weight: 600;
            padding-left: 6px;
            color: $color-gray600;
            @include max-screen($mobile) {
                font-size: 14px;
            }
        }
        img {
            width: 15px;
            cursor: pointer;
            margin-left: 5px;
        }
        picture {
            margin: 0;
            img {
                margin: 0;
                width: 25px;
                height: 25px;
                object-fit: cover;
                border-radius: 50%;
            }
        }
    }
    &__add {
        position: relative;
        button {
            border: 0;
            cursor: pointer;
            white-space: nowrap;
            background-color: transparent;
            img {
                width: 22px;
                margin-left: -3px !important;
            }
            span {
                font-size: 14px;
                font-weight: 500;
                padding-left: 2px;
                color: $color-gray500;
                width: calc(100% - 22px);
            }
        }
        :global {
            .select-dropdown {
                top: 0;
                left: 0;
                opacity: 0;
                cursor: pointer;
                position: absolute;
            }
            .select-dropdown__control {
                cursor: pointer;
            }
        }
    }
}