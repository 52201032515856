@import '../../assets/scss/variables.scss';
.rightSideBar {
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
    width: 92px;
    position: fixed;
    background-color: $color-white;
    border-left: 1px solid $color-gray300;
    @include max-screen($tablet) {
        transform: translateX(100%);
        transition: 0.3s ease-in-out;
        box-shadow: 0 0 2px rgba($color-black, 0.1);
    }
    ul {
        li {
            width: 52px;
            height: 52px;
            display: flex;
            padding: 10px;
            margin: 20px 0;
            cursor: pointer;
            border-radius: 8px;
            align-items: center;
            justify-content: center;
            background-color: $color-primary-secondary;
            img {
                max-height: 100%;
            }
        }
    }
    picture {
        top: 10px;
        width: 25px;
        height: 35px;
        right: 100%;
        padding: 3px;
        display: none;
        cursor: pointer;
        position: absolute;
        align-items: center;
        border-radius: 10px 0 0 10px;
        background-color: $color-white;
        box-shadow: 0 0 5px rgba($color-black, 0.1);
        @include max-screen($tablet) {
            display: flex;
        }
    }
    &__inner {
        height: 100%;
        overflow-y: auto;
        padding: 10px 20px;
    }
    &.active {
        transform: translateX(0);
        picture {
            img {
                transform: scaleX(-1);
            }
        }
    }
}