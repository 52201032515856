@import "../../assets/scss/variables.scss";
.modal {
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    &.active {
        animation: show 0.3s forwards;
    }
    @keyframes show {
        0% {
            opacity: 0;
            visibility: hidden;
        }
        100% {
            opacity: 1;
            visibility: visible;
        }
    }
    &__wrap {
        z-index: 1;
        max-width: 635px;
        border-radius: 16px;
        width: calc(100% - 30px);
        background-color: $color-white;
    }
    &__header {
        min-height: 80px;
        padding: 22px 34px;
        position: relative;
        border-bottom: 1px solid lighten($color-black, 80.39);
        @include max-screen($mobile) {
            padding: 15px 20px;
            min-height: 60px;
        }
        h4 {
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 0.48px;
            width: calc(100% - 40px);
            @include max-screen($mobile) {
                font-size: 20px;
            }
        }
        img {
            top: 23px;
            right: 34px;
            width: 32px;
            cursor: pointer;
            position: absolute;
            @include max-screen($mobile) {
                top: 18px;
                width: 25px;
                right: 12px;
            }
        }
    }
    &__content {
        padding: 24px 34px;
        overflow-y: auto;
        max-height: calc(100dvh - 220px);
        @include max-screen($mobile) {
            padding: 15px 20px;
        }
    }
    &__backdrop {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        background-color: rgba($color-black, 0.5);
    }
    &__footer {
        padding: 0 34px 35px;
        @include max-screen($mobile) {
            padding: 0 15px 20px;
        }
        button {
            &+button {
                flex: auto;
                margin-left: 14px !important;
            }
        }
    }
}