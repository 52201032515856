@import "../../assets/scss/variables.scss";

.customModal {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    visibility: hidden;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    background-color: rgba($color-black, 0.5);
    &.active {
        opacity: 1;
        visibility: visible;
    }
    &.isScroll {
        overflow-y: auto;
    }
    &__backdrop {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }
    &__content {
        z-index: 1;
        max-width: 600px;
        position: relative;
        border-radius: $radius;
        width: calc(100% - 20px);
        background-color: $color-white;
    }
    &__inner {
        padding: 0 24px 16px;
        overflow-y: auto;
        max-height: calc(100vh - 220px);
        p {
            font-size: 16px;
            line-height: 1.65;
        }
        &.noSpacing {
            padding: 0;
            overflow: visible;
            max-height: none;
        }
        &.noScroll {
            max-height: none;
            overflow: visible;
        }
    }

    &__header {
        padding: 18px 24px;
        border-radius: $radius $radius 0 0;
        img {
            width: 32px;
            cursor: pointer;
            align-self: flex-start;
        }
        h3 {
            font-weight: 600;
            line-height: 1.15;
            margin-bottom: 0;
            font-size: 24px;
            padding-right: 10px;
            width: calc(100% - 32px);
            @include max-screen($mobile) {
                font-size: 18px;
            }
            img {
                width: 20px;
                margin-left: 3px;
                display: inline-block;
                vertical-align: middle;
            }
        }
        &.postFix {}
    }
    &__footer {
        padding: 16px 24px 24px;
        button {
            &+button {
                flex: auto;
                margin-left: 14px !important;
            }
        }
    }
    &.transitionDisable {
        transition: none;
    }
    &.bottomBorder {
        .customModal__header {
            margin-bottom: 10px;
            border-bottom: 1px solid lighten($color-black, 80.39);
        }
    }
}