@import '../../../../assets/scss/variables.scss';
.moduleInstruction {
    width: 100%;
    padding: 15px;
    letter-spacing: 0.3px;
    border-radius: 12px;
    margin: 30px 0;
    text-align: justify;
    border: 1.5px solid $color-dark;
    background-color: rgba($color-dark, 0.1);
    font-style: italic;
    p {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}