@import '../../assets/scss/variables.scss';
.section {
    &__content {
        min-height: 100dvh;
        padding: 30px 30px 30px 80px;
        background-color: $color-gray;
        @include max-screen($mobile) {
            padding: 15px;
        }
    }
    &__pagination {
        padding-bottom: 30px;
        @include max-screen($tablet-sm) {
            padding-bottom: 20px;
            flex-direction: column-reverse;
        }
        h1 {
            font-size: 28px;
            width: calc(100% - 420px);
            @include max-screen($desktop) {
                font-size: 26px;
                width: calc(100% - 270px);
            }
            @include max-screen($tablet-sm) {
                width: 100%;
                font-size: 22px;
                margin-top: 20px;
            }
            span {
                display: inline;
            }
            >div {
                width: 100%;
            }
            img {
                width: 25px;
                min-width: 25px;
                cursor: pointer;
                margin-left: 5px;
                display: inline-block;
                vertical-align: middle;
            }
        }
        picture {
            margin: 0 10px;
            img {
                height: 25px;
                cursor: inherit;
                object-fit: cover;
                border-radius: 50%;
                margin: 0 -10px 0 0;
                background-color: $color-white;
                border: 0.5px solid $color-gray400;
            }
            >div {
                position: static;
                margin: 0 -10px 0 0;
                display: inline-flex;
                vertical-align: middle;
                background-color: $color-gray200;
            }
            &:empty {
                display: none;
            }
        }
        aside {
            width: 400px;
            @include max-screen($desktop) {
                width: 250px;
            }
            @include max-screen($tablet-sm) {
                width: 100%;
            }
        }
    }
    &__bottom {
        aside {
            width: 250px;
            margin-right: 20px;
            @include max-screen($tablet-sm) {
                width: 100%;
            }
        }
    }
}