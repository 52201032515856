@import '../../../../assets/scss/variables.scss';
.infoBlock {
    width: 100%;
    margin-bottom: 30px;
    h4 {
        font-size: 18px;
        font-weight: 600;
        line-height: 1.1;
        margin-bottom: 12px;
        letter-spacing: 0.3px;
        color: $color-gray600;
    }
    :global .tiptap {
        width: 100%;
        padding: 15px;
        letter-spacing: 0.3px;
        text-align: justify;
        border-radius: 12px;
        border: 1.5px solid $color-dark;
        background-color: rgba($color-dark, 0.1);

        p {
            font-size: 14px;
            margin: 0 0 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        ul {
            padding-left: 5px;
            margin-bottom: 10px;
            li {
                position: relative;
                padding-left: 10px;
                margin-bottom: 5px;
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    top: 7px;
                    left: 0;
                    width: 4px;
                    height: 4px;
                    content: '';
                    border-radius: 50%;
                    position: absolute;
                    background-color: currentColor;
                }
                p {
                    margin: 0;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}