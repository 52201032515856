@import "../../assets/scss/variables.scss";
.inputField {
    position: relative;
    margin-bottom: 14px;
    textarea,
    input {
        width: 100%;
        height: 40px;
        outline: none;
        font-size: 16px;
        appearance: none;
        font-weight: 500;
        padding: 8px 15px;
        color: $color-gray600;
        border-radius: $radius;
        background-color: transparent;
        border: 1.5px solid $color-gray300;
        font-family: $font-family-base;
        &::placeholder {
            color: $color-gray400;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        &[type="number"] {
            -moz-appearance: textfield;
        }
        &:not(:placeholder-shown),
        &:focus {
            border-color: $color-gray600;
        }
        &:disabled {
            user-select: none;
            background-color: $color-gray200;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            transition: background-color 5000s ease-in-out 0s;
        }
        &[readonly] {
            opacity: 0.3;
            pointer-events: none;
            border-color: $color-gray300;
            background-color: rgba($color-gray300, 0.4);
        }
        &::-ms-reveal,
        &::-ms-clear {
            display: none;
        }
    }
    input:autofill {
        background: transparent;
    }

    textarea {
        display: block;
        height: 136px;
        resize: none;
        padding-top: 16px;
        padding-bottom: 16px;
    }
    span {
        font-size: 12px;
        margin-top: 3px;
        display: block;
        padding: 0 3px;
        color: $color-error;
    }
    &__inner {
        position: relative;
    }
    &__visibility,
    &__icon {
        top: 0;
        left: 0;
        z-index: 1;
        width: 38px;
        height: 100%;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        img {
            max-width: 18px;
            max-height: 18px;
        }
    }
    &__visibility {
        left: auto;
        right: 0;
        width: auto;
        cursor: pointer;
        padding-right: 18px;
        img {
            width: 18;
        }
    }
    &__edit {
        top: 18px;
        width: 20px;
        right: 15px;
        height: 20px;
        display: flex;
        cursor: pointer;
        position: absolute;
        align-items: center;
        justify-content: center;
    }
    &.inputPassword {
        input {
            padding-right: 40px;
        }
    }
    &.isError {
        input {
            border-color: $color-error;
        }
    }
    &.noSpacing {
        margin-bottom: 0;
    }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px $color-white inset;
}