@import "../../../../assets/scss/variables.scss";

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 30px;
    &__options {
        right: 0;
        border: 0;
        bottom: 0;
        z-index: 6;
        width: 20px;
        outline: none;
        padding-right: 4px;
        cursor: pointer;
        position: absolute;
        background-color: $color-light;
        img {
            cursor: pointer;
        }
    }
    &__optionMenu {
        padding: 5px 0;
        border-radius: 10px;
        background-color: $color-white;
        box-shadow: 0 0 10px rgba($color-black, 0.1);
        ul {
            li {
                a {
                    display: flex;
                    align-items: center;
                    padding: 5px 15px;
                    span {
                        font-size: 20px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}