@import "../../assets/scss/variables.scss";
.dashboard {
    min-height: 100dvh;
    @include min-screen($desktop-lg) {
        transition: 0.3s ease-in-out padding;
    }
    :global .rightSide & {
        @include min-screen($tablet) {
            padding-right: 92px;
        }
    }
    :global .rightSideBarOpen & {
        @include min-screen($desktop-lg) {
            padding-right: 500px;
        }
    }
    &__header {
        margin-bottom: 24px;
        h1 {
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 0.48px;
            @include max-screen($mobile) {
                font-size: 22px;
            }
        }
        button {
            img {
                margin-right: 10px;
                @include max-screen($mobile) {
                    margin-right: 3px;
                    margin-left: -5px;
                }
            }
        }
    }
    &__tab {
        margin-bottom: 22px;
    }
    &__content {
        padding: 30px;
        @include max-screen($tablet) {
            padding: 25px 15px;
        }
    }
}